// import React from 'react';
// import PropTypes from 'prop-types';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import useScrollTrigger from '@material-ui/core/useScrollTrigger';
// import Container from '@material-ui/core/Container';
// import Slide from '@material-ui/core/Slide';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles({
//     root: {
//         width: '100%',
//         maxWidth: '100%',
//         marginTop: '20px'
//     },
//     AppBar: {
//         justifyContent: 'center',
//         backgroundColor: '#272a33'
//     }
// });

// function HideOnScroll(props) {
//     const { children, window } = props;
//     // Note that you normally won't need to set the window ref as useScrollTrigger
//     // will default to window.
//     // This is only being set here because the demo is in an iframe.
//     const trigger = useScrollTrigger({ target: window ? window() : undefined });

//     return (
//         <Slide appear={false} direction="down" in={!trigger}>
//             {children}
//         </Slide>
//     );
// }

// HideOnScroll.propTypes = {
//     children: PropTypes.element.isRequired,
//     /**
//      * Injected by the documentation to work in an iframe.
//      * You won't need it on your project.
//      */
//     window: PropTypes.func,
// };

// export default function ReturnCancellationPolicy(props) {
//     const classes = useStyles();
//     return (
//         <React.Fragment>
//             <div className={classes.root}>
//                 <CssBaseline />
//                 <HideOnScroll {...props}>
//                     <AppBar >
//                         <Toolbar className={classes.AppBar}>
//                             <Typography variant="h6">RETURN & CANCELLATION POLICY</Typography>
//                         </Toolbar>
//                     </AppBar>
//                 </HideOnScroll>
//                 <Toolbar />
//                 <Container maxWidth="md">
//                     <Typography variant="body2" gutterBottom>Ilearnplace  believes in helping its
//                         customers as far as possible, and has therefore a liberal return and cancellation
//                         policy. Under this policy: If there are any damages or missing parts when you receive
//                         the order from us, we give 1 week for customers to report to us, and we will replace the
//                         damaged products or send you the missing parts within 10 days. However, the case will not
//                         be accepted if the products were damaged by inappropriate use or carelessness. To the product
//                         need to be exchanged, firstly, please send the photos of damaged products to us. We will
//                         estimate the damages then decide the best way to exchange or return the product. We accept no
//                         responsibility for improper installation of our products. Electrical polarity must be properly
//                         observed in hooking up electrical components. We also accept no responsibility for damages caused
//                         during the shipping or transit of the goods. In case you feel that the product received is not as
//                         shown on the site or as per your expectations, you must bring it to the notice of our customer
//                         service within 24 hours of receiving the product. The Customer Service Team after looking into your
//                         complaint will take an appropriate decision. Cancellations and Refund will be considered only if
//                         the request is made before we have initiated the process of shipping them. Once the product shipping
//                         has been initiated, cancellation and refund is not available. At all times, it shall be the sole
//                         discretion of the Ilearnplace to determine whether the product is deemed to be returned and/or
//                         replaced.</Typography>

//                 </Container>
//             </div>
//         </React.Fragment>
//     );
// }




// //                 </Typography>
// //             </Container>
// //         </React.Fragment>
// //     );
// // }



import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: '100%',
        marginTop: '20px',
    },
    AppBar: {
        justifyContent: 'center',
        backgroundColor: '#272a33',
    },
});

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function ReturnCancellationPolicy(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.root}>
                <CssBaseline />
                <HideOnScroll {...props}>
                    <AppBar>
                        <Toolbar className={classes.AppBar}>
                            <Typography variant="h6">RETURN & CANCELLATION POLICY</Typography>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Toolbar />
                <Container maxWidth="md">

                    <Typography variant="body2" gutterBottom>
                        This refund and cancellation policy outlines how you can cancel or seek a refund for a product / service that you have purchased through the Platform. Under this policy:
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        1. Cancellations will only be considered if the request is made 3 days of placing the order. However, cancellation requests may not be entertained if the orders have been communicated to such sellers / merchant(s) listed on the Platform and they have initiated the process of shipping them, or the product is out for delivery. In such an event, you may choose to reject the product at the doorstep.
                    </Typography>
                    
                    <Typography variant="body2" gutterBottom>
                        2. In case of receipt of damaged or defective items, please report to our customer service team. The  request would be entertained once the seller/ merchant listed on the Platform, has checked and  determined the same at its own end. This should be reported within 3 days of receipt of products.  In case you feel that the product received is not as shown on the site or as per your expectations,  you must bring it to the notice of our customer service within 3 days of receiving the product. The  customer service team after looking into your complaint will take an appropriate decision.</Typography>
                    <Typography variant="body2" gutterBottom>
                        3. In case of complaints regarding the products that come with a warranty from the manufacturers,  please refer the issue to them. </Typography>
                    <Typography variant="body2" gutterBottom>
                        4. In case of any refunds approved by iLearnPlace, it will take 7 days for the refund to be credited to your account.</Typography>
                    
                    <Typography variant='h6' gutterBottom>Return Policy</Typography>
                    <Typography variant="body2" gutterBottom>
                    We offer refund / exchange within first 7 days from the date of your purchase. If 7 days have passed  since your purchase, you will not be offered a return, exchange or refund of any kind. In order to become  eligible for a return or an exchange, (i) the purchased item should be unused and in the same condition as  you received it, (ii) the item must have original packaging, (iii) if the item that you purchased on a sale,  then the item may not be eligible for a return / exchange. Further, only such items are replaced by us  (based on an exchange request), if such items are found defective or damaged. </Typography>

                    <Typography variant="body2" gutterBottom>You agree that there may be a certain category of products / items that are exempted from returns or  refunds. Such categories of the products would be identified to you at the item of purchase. For exchange  / return accepted request(s) (as applicable), once your returned product / item is received and inspected  by us, we will send you an email to notify you about receipt of the returned / exchanged product. Further.  If the same has been approved after the quality check at our end, your request (i.e. return / exchange) will  be processed in accordance with our policies.</Typography>


                </Container>
            </div>
        </React.Fragment>
    );
}
